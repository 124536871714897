import Vue from 'vue'
import authToken from 'base/api/auth-token'
import balance from 'base/api/balance'

// Время в секундах. За сколько секунд до истечения токена его необходимо обновить
const TOKEN_REFRESH_EXPIRED_THRESHOLD = 23 * 60 * 60

export default {
  namespaced: true,
  state: {
    authenticated: false,
    role: null,
    balance: false
  },
  mutations: {
    authenticate: (state) => {
      state.authenticated = true
    },
    unauthenticate: (state) => {
      state.authenticated = false
    },
    setRole: (state, role) => {
      state.role = role
      localStorage.setItem('role', role)
    },
    setBalance: (state, balance) => {
      state.balance = balance
    }
  },
  getters: {
    authHeader: state => () => {
      return {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }
  },
  actions: {
    login ({ commit, dispatch }, payload) {
      Vue.$logger.debug('user | Log in: role = ' + payload.role)

      localStorage.setItem('token', payload.data.token)
      localStorage.setItem('expires_at', payload.data.expires_at)

      commit('authenticate')
      commit('setRole', payload.role)

      dispatch('getBalance')
      dispatch('tasks/get', null, { root: true })
    },

    loginGuest ({ commit, dispatch }) {
      Vue.$logger.debug('user | loginGuest')

      return dispatch('logout')
    },

    logout ({ commit, state, dispatch }) {
      Vue.$logger.debug('user | Logout')

      return new Promise((resolve, reject) => {
        commit('setRole', 'guest')
        localStorage.removeItem('token')
        localStorage.removeItem('expires_at')
        resolve()
      })
    },

    checkAuth ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        Vue.$logger.debug('user | Checking authorized status')

        const role = localStorage.getItem('role')
        const token = localStorage.getItem('token')
        const loginToken = localStorage.getItem('login_token')
        const expiresAt = localStorage.getItem('expires_at')
        const currentTime = Math.ceil((new Date()).getTime() / 1000)
        const urlParams = new URLSearchParams(window.location.search)

        // Если токен логина не совпадает с хранящимся в Local Storage, то переавторизовываем пользователя
        if (loginToken !== urlParams.get('token')) {
          Vue.$logger.debug('user | Login token mismatch. Logging in as a guest...')

          localStorage.removeItem('login_token')

          dispatch('loginGuest').then(() => {
            resolve()
          })
        } else {
          // Если не гость
          if (role !== 'guest') {
            // Проверим, существует ли токен в локалсторадже
            if (token) {
              Vue.$logger.debug('user | Token exists')

              Vue.$logger.debug('user | Checking token expire time: current time = ' + currentTime)
              // Если задано время жизни токена, и оно еще не истекло
              if (expiresAt && currentTime < expiresAt) {
                Vue.$logger.debug('user | Token is not expired: expiration time = ' + expiresAt)

                // Если токен истечёт в ближайшее время (этот порог задается TOKEN_REFRESH_EXPIRED_THRESHOLD в секундах)
                if (currentTime >= expiresAt - TOKEN_REFRESH_EXPIRED_THRESHOLD) {
                  Vue.$logger.debug('user | Token will be expired soon. Refreshing...')

                  // Обновляем токен
                  authToken.refresh(this)
                    .then(function (response) {
                      const data = response.data

                      dispatch('login', { data, role }).then(function (response) {
                        Promise.all([dispatch('getBalance'), dispatch('tasks/get', null, { root: true })]).then(() => {
                          resolve()
                        }).catch(function () {
                          reject(new Error('checkAuth | authToken.refresh | ошибка'))
                          dispatch('loginGuest').then(() => {
                            resolve()
                          })
                        })
                      })
                    }).catch(function () {
                      reject(new Error('checkAuth | authToken.refresh | ошибка'))
                      dispatch('loginGuest').then(() => {
                        resolve()
                      })
                    })
                } else {
                  commit('authenticate')

                  if (localStorage.getItem('role')) {
                    commit('setRole', localStorage.getItem('role'))
                  } else {
                    commit('setRole', 'guest')
                  }

                  if (this.state.user.role !== 'guest') {
                    Promise.all([dispatch('getBalance'), dispatch('tasks/get', null, { root: true })]).then(() => {
                      resolve()
                    }).catch(function () {
                      reject(new Error('checkAuth | error getting balance or tasks'))
                    })
                  } else {
                    resolve()
                  }
                }
              } else {
                Vue.$logger.debug('user | Token expired. Logging in as a guest...')

                dispatch('loginGuest').then(() => {
                  resolve()
                })
              }
            } else {
              Vue.$logger.debug('user | Token doesn\'t exist. Logging in as a guest...')

              dispatch('loginGuest').then(() => {
                resolve()
              })
            }
          } else {
            resolve()
          }
        }
      })
    },

    getBalance ({ commit, dispatch }) {
      Vue.$logger.debug('user | Getting balance')

      return new Promise((resolve, reject) => {
        balance.get(this)
          .then(function (response) {
            commit('setBalance', response.data.balance)
            resolve()
          }).catch(function () {
            reject(new Error('user | balance.get | ошибка'))
          })
      })
    }
  }
}
